const PHONE_NUMBER_REFERRED='phone_number_referred'
const ID_REFERRER='id_referrer'
const TOKEN='token'
const BEARER='Bearer '
const OTP='otp'
const COUNTRY_CODE_PHONE_NUMBER_REFERRED='country_code_phone_number_referred'

export default {
  ID_REFERRER,
  PHONE_NUMBER_REFERRED,
  TOKEN,
  BEARER,
  OTP,
  COUNTRY_CODE_PHONE_NUMBER_REFERRED
};