import GeneralConstants from "../constants/GeneralConstants"

const setPhoneNumberReferred = (phone_number_referred) => {
    localStorage.setItem(GeneralConstants.PHONE_NUMBER_REFERRED, phone_number_referred)
}
const getPhoneNumberReferred = () => {
    return localStorage.getItem(GeneralConstants.PHONE_NUMBER_REFERRED)
}
const setCountryCodePhoneNumberReferred = (country_code_phone_number_referred) => {
    localStorage.setItem(GeneralConstants.COUNTRY_CODE_PHONE_NUMBER_REFERRED, country_code_phone_number_referred)
}
const getCountryCodePhoneNumberReferred = () => {
    return localStorage.getItem(GeneralConstants.COUNTRY_CODE_PHONE_NUMBER_REFERRED)
}

const setIdReferrer = (id_referrer) => {
    localStorage.setItem(GeneralConstants.ID_REFERRER, id_referrer)
}
const getIdReferrer = () => {
    return localStorage.getItem(GeneralConstants.ID_REFERRER)
}

const setToken = (token) => {
    localStorage.setItem(GeneralConstants.TOKEN, token)
}
const getToken = () => {
    return localStorage.getItem(GeneralConstants.TOKEN)
}

const setOTP = (value) => {
    localStorage.setItem(GeneralConstants.OTP, value)
}
const getOTP = () => {
    return localStorage.getItem(GeneralConstants.OTP)
}
const removeStorage=()=>{
    localStorage.clear()
}

export default {
    setPhoneNumberReferred,
    getPhoneNumberReferred,
    setIdReferrer,
    getIdReferrer,
    setToken,
    getToken,
    setOTP,
    getOTP,
    removeStorage,
    setCountryCodePhoneNumberReferred,
    getCountryCodePhoneNumberReferred
};