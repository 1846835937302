import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import HomeError from './pages/home/HomeError';
import Otp from './pages/otp/Otp';
import OtpError from './pages/otp/OtpError';
import End from './pages/End';

export default () => (
  <div className="app">
    <BrowserRouter>
      <Routes>
        <Route path="/:referrerPhoneNumber" element={<Home />} />
        <Route path="/home-error" element={<HomeError />} />
        <Route path="/otp" element={<Otp />} />
        <Route path="/otp-error" element={<OtpError />} />
        <Route path="/end" element={<End />} 
        />
      </Routes>
    </BrowserRouter>
  </div>
);
