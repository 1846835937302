const config = require('../../package.json').projectConfig;
const REFERRAL_BACKEND_BASE_URL = config.referralBackendApiBaseUrl;

const BACKEND_REFERRAL_API = {
  BASE_API_URL:`${REFERRAL_BACKEND_BASE_URL}/processor/referral`,
  REFERRAL: "/",
  SEND_OTP: "/send-otp",
  CREATE_TEMPORAL_REFERRED: "/create-temporal-referred"
};

export default {
  BACKEND_REFERRAL_API
};
