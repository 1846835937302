import { createGlobalStyle } from 'styled-components';

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src: url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500&display=swap');

  }
  body {
    font-family: 'Inter', sans-serif;
  }
`;

export default Fonts;
