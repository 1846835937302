import React, { useState, useRef, useEffect } from 'react';
import LocalStorage from '../../storage/LocalStorage';
import { useNavigate } from 'react-router-dom';
import { Button, message} from 'antd';
import bcrypt from 'bcryptjs';
import ReferralService from '../../services/ReferralService'

import '../styles/otp.css'

const Otp = () =>{
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData(){
        if(!LocalStorage.getOTP()){
          message.error("OTP no generado")
          navigate('/home-error')
        }
        }
        fetchData()
    }, [])

    const decodedParameter = decodeURIComponent(LocalStorage.getOTP());

    const [otp, setOtp] = useState(['', '', '', '']);
    const otpInputs = useRef([]);
  
    const handleOtpChange = (e, index) => {
      const value = e.target.value;
      if (value.length <= 1) {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
  
        // Mover el foco al siguiente input
        if (value.length === 1 && index < otpInputs.current.length - 1) {
          otpInputs.current[index + 1].focus();
        }
  
        // Regresar al input anterior si se borra el dígito
        if (value.length === 0 && index > 0) {
          otpInputs.current[index - 1].focus();
        }
      }
    };
  
    const handleValidate = () => {
      const otpValue = otp.join('');
  
      if (!(otpValue.length < 4)) {
        bcrypt.compare(otpValue, decodedParameter, async (err, result) => {
          if (err) {
            message.error("OTP invalido")
            return;
          }
          if (result) {
            await ReferralService.createTemporalReferred(LocalStorage.getIdReferrer(), LocalStorage.getPhoneNumberReferred())
            .then( res => {
              if (res.status === 200) {
                LocalStorage.removeStorage()
                message.success("OTP valido")
                navigate('/end');
              }
          })
          .catch((err) => {
              message.error(err.response.data.response.message)
              navigate('/otp-error');
              LocalStorage.removeStorage()
              console.log(err.response.data.response.message);
          });
          } else {
            message.error("OTP invalido")
          }
        });
      }
      else{
        message.error("OTP invalido")
      }
    };
  
    const handleGoBack = () => {
      LocalStorage.removeStorage()
      navigate(-1);
    };
  
    return (
      <div className="validate-otp-page">
        <div className="validate-otp-content-container">
        <div style={styles['.text']}>
            <h2 style={{color: '#1D1B38'}}>Verifica tu número telefónico</h2>
            <p>Hemos enviado un código de verificación de 4 dígitos por SMS al número que registraste.</p>
        </div>
        <div style={styles['.text']}>
            <p>Tu número:</p>
            <p>{LocalStorage.getCountryCodePhoneNumberReferred()} {LocalStorage.getPhoneNumberReferred()}</p>
        </div>
        <div>
            <button onClick={handleGoBack} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer',color: '#444444', fontSize: '13px' }}>
                ¿Quieres <span style={{textDecoration: 'underline', color: '#3366CC'}} >cambiar tu número?</span> 
            </button>
        </div>
        <div style={styles['.text']}>
            <p>Ingresa el código</p>
        </div>
          {otp.map((digit, index) => (
            <input
              key={index}
              ref={(input) => (otpInputs.current[index] = input)}
              type="number"
              maxLength="1"
              value={digit}
              onChange={(e) => handleOtpChange(e, index)}
            />
          ))}
          <div>
            <button style={styles['.button']} onClick={handleValidate}>Continuar</button>
          </div>
        </div>
      </div>
    );

}
const styles = {
  '.text': {
    fontSize: '16px',
    textAlign: 'left',
    color: '#444444'
  },
  '.button': {
    fontSize: '16px',
    width: '328px',
    height: '48px',
    padding: '12px 16px 12px 16px',
    backgroundColor: '#29C350',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
    marginTop: '50px',
    borderRadius: '16px'
  },
  
  // Mobile responsiveness
  '@media (max-width: 576px)': {
    '.text': {
      fontSize: '16px',
      textAlign: 'left',
    },
    '.button': {
      fontSize: '16px',
      width: '328px',
      height: '48px',
      padding: '12px 16px 12px 16px',
      backgroundColor: '#29C350',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      marginTop: '50px',
      borderRadius: '16px'
    },
  },
};

export default Otp;