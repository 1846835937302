import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Fonts from './Fonts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Fonts />
        <App />
    </>
);
