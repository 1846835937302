import React, {useEffect} from 'react';
import {logos} from '../../constants/AssetsConstants'
import { useNavigate } from 'react-router-dom';
import LocalStorage from '../../storage/LocalStorage';

const HomeError = () =>{

    // init
    useEffect(() => {
        async function fetchData(){
            LocalStorage.removeStorage()
        }
        fetchData()
    }, [])
    // END init

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1);
      };

    return (
        <div style={styles['.welcome-to-midas']}>
            <div style={styles['.logo']}>
                <img src={logos.MIDAS_ERROR_LOGO} alt="Midas Logo" />
            </div>
            <div style={styles['.text']}>
                <h2 style={{color: '#1D1B38'}}>Este número no existe</h2>
                <p>Por favor, verifica su escritura y vuelve a intentarlo.</p>
            </div>
            <div>
                <button style={styles['.button']} onClick={handleGoBack}>Regresar</button>
            </div>
            
        </div>
    )

}

const styles = {
    '.welcome-to-midas': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '70vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '.logo': {
      width: '328px',
      height: '200px',
      marginBottom: '20px',
    },
    '.text': {
      fontSize: '16px',
      textAlign: 'center',
      color: '#444444'
    },
    '.button': {
      fontSize: '16px',
      width: '328px',
      height: '48px',
      padding: '12px 16px 12px 16px',
      backgroundColor: '#29C350',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      marginTop: '50px',
      borderRadius: '16px',
    }
  };

export default HomeError;