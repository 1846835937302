import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import LocalStorage from '../../storage/LocalStorage'
import { useNavigate } from 'react-router-dom';
import ReferralService from '../../services/ReferralService'
import {logos} from '../../constants/AssetsConstants'
import { Input, Button, Select, Space, message } from 'antd';

const Home = () =>{
    const { referrerPhoneNumber } = useParams();
    let navigate = useNavigate();

    // init
    useEffect(() => {
        async function fetchData(){
        getReferrerInfo(referrerPhoneNumber)
        }
        fetchData()
    }, [])
    // END init

    // const, states, ref, effects(service_request) for services request
    const [referrerName, setReferrerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('+52');
    const [flagCountryCode, setFlagCountryCode] = useState('🇲🇽 +52');
    // END const, states, ref, effects(service_request) display information

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    //COMMON services

    // service 
    async function getReferrerInfo(referrerPhoneNumber) {
        await ReferralService.referral(referrerPhoneNumber)
          .then( res => {
            if (res.status === 200) {
                LocalStorage.setIdReferrer(res.data.client_obj.ctp_id_client_information)
                setReferrerName(`${res.data.client_obj.client_information.cte_first_name} ${res.data.client_obj.client_information.cte_father_last_name}`)
            }
        })
        .catch((err) => {
            message.error(`${referrerPhoneNumber} no existe`)
            navigate('/home-error');
            console.log(err.response.data.response.message);
        });
      }
    // END

    // logic
    const handlePhoneNumberChange = (e) => {
        const value = e.target.value.replace(/\D/g, '');
        if (value.length <= 10) {
          setPhoneNumber(value);
        }
      };
    
      const handleCountryCodeChange = (value, label) => {
        setFlagCountryCode(label.label)
        setCountryCode(value);
      };
    
      const handleSubmit = async () => {
        if (phoneNumber.length !== 10) {
          message.error('Por favor, ingresa un número de teléfono válido de 10 dígitos.');
          return;
        }else{
          await ReferralService.sendOtp(countryCode, phoneNumber).then(res =>{
            if(res.status === 200){
                LocalStorage.setOTP(res.data.response.otp)
                LocalStorage.setPhoneNumberReferred(phoneNumber)
                LocalStorage.setCountryCodePhoneNumberReferred(flagCountryCode)
                message.success(`OTP de 4 digitos enviado al ${flagCountryCode} ${phoneNumber}`)
                navigate(`/otp`);
                setPhoneNumber('');
            }else{
              message.error("Verifica que tu telefono sea valido o el numero ingresado ya es usuario Midas");
            }
          }).catch(err => {
            message.error("Verifica que tu telefono sea valido ó el numero ingresado ya es usuario Midas");
          })
        }
      };
    
      const options = [
        { label: '🇲🇽 +52', value: '+52' },
        { label: '🇺🇸 +1', value: '+1' },
      ];
    // END

    return (
        <div style={styles['.welcome-to-midas']}>
            <div style={styles['.logo']}>
                <img src={logos.MIDAS_REFERRAL_HOME_IMG} alt="Midas Logo" />
            </div>

            <div style={styles['.text']}>
                <h2 style={{color: '#1D1B38'}}>¡Bienvenido a Midas!</h2>
                <p>Has sido invitado por <span style={{ color: '#29C350', fontWeight: 'bold'}}>{referrerName}</span>.</p>
                <p>Regístrate con tu número telefónico y comienza a ganar Puntos Midas. Cada punto es un peso.</p>
            </div>

            <Space.Compact>
            <Select 
              style={{
                marginTop: '50px',
                height: '48px'
            }} 
              defaultValue={countryCode} 
              onChange={handleCountryCodeChange} 
              options={options} />
            <Input 
                style={styles['.input']}
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                placeholder="Número telefónico"
                maxLength={10}
            />
            </Space.Compact>

            <Button style={styles['.button']} onClick={handleSubmit}>
                Continuar
            </Button>
        </div>
    )

}

const styles = {
    '.welcome-to-midas': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '.logo': {
      width: '380px',
      height: '200px',
      marginBottom: '20px',
    },
    '.text': {
      fontSize: '16px',
      textAlign: 'left',
      color: '#444444'
    },
    '.input': {
      width: '248px',
      height: '48px',
      padding: '10px',
      marginTop: '50px',
      color: '#615F74',
      fontSize:'16px',
      type:"number"
    },
    '.button': {
      fontSize: '16px',
      width: '328px',
      height: '48px',
      padding: '12px 16px 12px 16px',
      backgroundColor: '#29C350',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      marginTop: '50px',
      borderRadius: '16px'
    },
    
    // Mobile responsiveness
    '@media (max-width: 576px)': {
      '.welcome-to-midas': {
        flexDirection: 'row',
        justifyContent: 'space-between',
      },
      '.welcome-to-midas': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      },
      '.logo': {
        width: '380px',
        height: '200px',
        marginBottom: '20px',
      },
      '.text': {
        fontSize: '16px',
        textAlign: 'left',
      },
      '.input': {
        width: '248px',
        height: '48px',
        padding: '10px',
        marginTop: '50px',
      },
      '.button': {
        fontSize: '16px',
        width: '328px',
        height: '48px',
        padding: '12px 16px 12px 16px',
        backgroundColor: '#29C350',
        color: '#fff',
        border: 'none',
        cursor: 'pointer',
        marginTop: '50px',
        borderRadius: '16px'
      },
    },
  };

export default Home;