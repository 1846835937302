import React from 'react';
import {logos} from '../constants/AssetsConstants'

const End = () =>{

    const handleDownloadClick = () => {
        // Redirect to the specified URL
        window.location.href = "https://onelink.to/n5tbbd";
        window.close();
      };
    return (
        <div style={styles['.welcome-to-midas']}>
            <div style={styles['.logo']}>
                <img src={logos.MIDAS_SUCCESS} alt="Midas Logo" />
            </div>
            <div style={styles['.text']}>
                <h2 style={{color: '#1D1B38'}}>¡Ya eres parte de Midas!</h2>
                <p>Comienza a sumar puntos en comercios afiliados. Necesitarás la app para pagar con puntos.</p>
            </div>
            <div>
                <img style={styles['.qr-image']} src={logos.QR_ONE_LINK} alt="Midas qr" />
            </div>
            <div>
                <button style={styles['.button']} onClick={handleDownloadClick}>Descargar Midas</button>
            </div>
        </div>
    )

}

const styles = {
    '.welcome-to-midas': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '70vh',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '.logo': {
      width: '328px',
      height: '200px',
      marginBottom: '20px',
    },
    '.text': {
      fontSize: '16px',
      textAlign: 'center',
      color: '#444444'
    },
    '.button': {
      fontSize: '16px',
      width: '328px',
      height: '48px',
      padding: '12px 16px 12px 16px',
      backgroundColor: '#29C350',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      marginTop: '50px',
      borderRadius: '16px',
    //   display: 'none'
    },
    '.qr-image': {
        width: '150px',
        height: '150px',
    },
    '@media(max-width: 600px)': {
        '.qr-image': {
            display: 'none',
        },
          '.button': {
            fontSize: '16px',
            width: '328px',
            height: '48px',
            padding: '12px 16px 12px 16px',
            backgroundColor: '#29C350',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
            marginTop: '50px',
            borderRadius: '16px',
          }
    }
    
  };

export default End;