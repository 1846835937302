import axios from "axios";
import ApiConstants from "../constants/ApiConstants";

///////////////////////////////////////////////////////////////////////
const ReferralRequest = axios.create({
    baseURL: ApiConstants.BACKEND_REFERRAL_API.BASE_API_URL,
    timeout: 30000,
});

///////////////////////////////////////////////////////////////////////
const referral = (client_phone) => {
    const config = {
      params: {
        client_phone: client_phone,
      }
    }
    return ReferralRequest.get(
            ApiConstants.BACKEND_REFERRAL_API.REFERRAL,
            config
        );
}
///////////////////////////////////////////////////////////////////////
const sendOtp = async (phone_code, phone_number) => {
    const data = {
      phone_number: phone_number,
      phone_code: phone_code
    }
    return ReferralRequest.post(
            ApiConstants.BACKEND_REFERRAL_API.SEND_OTP,
            data
        );
}
///////////////////////////////////////////////////////////////////////
const createTemporalReferred = async (id_referrer, phone_number_referred) => {
  const data = {
    id_referrer: id_referrer,
    phone_number_referred: phone_number_referred
  }

  return ReferralRequest.post(
          ApiConstants.BACKEND_REFERRAL_API.CREATE_TEMPORAL_REFERRED,
          data
      );
}
///////////////////////////////////////////////////////////////////////


export default {
  referral,
  sendOtp,
  createTemporalReferred
}